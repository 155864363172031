import React,{useContext} from "react";
import { GlobalDataContext } from "../../context/context";

const Block_13 = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return(
        <section className="w-full min-h-[500px] bg-cover bg-fixed bg-no-repeat bg-center grid place-items-center"
        style={{ background: `url("${rpdata?.stock?.[10]}")` }}>

            <div className="w-4/5 h-[90%] bg-[#0089b6c9] flex flex-col justify-center p-10">
                <h1 className="text-white"> {
                                                rpdata?.dbPrincipal?.exprYears ?
                                                    `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                                    : 'we have many years of experience'
                                            }</h1>
                <p>{rpdata?.dbHome?.[0].text}</p>
            </div>

        </section>
    )

}

export default Block_13